export const buyboxATVPlusTranslations = {
	US: {
		offerText: '$2.99 for 3 months',
		disclaimerText:
			'*$2.99/month for first 3 months, then $9.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	GB: {
		offerText: '£2.99 for 3 months',
		disclaimerText:
			'*£2.99/month for first 3 months, then £8.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	FR: {
		offerText: '€4.99 pour 3 mois',
		disclaimerText:
			'*€4.99/mois pendant les 3 premiers mois, puis €9.99/mois pour les abonnés éligibles sur les appareils éligibles uniquement. Conditions applicables.',
	},
	DE: {
		offerText: '€4.99 für 3 Monate',
		disclaimerText:
			'*€4.99/Monat für die ersten 3 Monate, dann €9.99/Monat nur für berechtigte Abonnenten auf berechtigten Geräten. Es gelten die Bedingungen.',
	},
	CA: {
		offerText: 'CA$3.99 for 3 months',
		disclaimerText:
			'*CA$3.99/month for first 3 months, then CA$12.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	AU: {
		offerText: 'A$3.99 for 3 months',
		disclaimerText:
			'*A$3.99/month for first 3 months, then A$12.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	ES: {
		offerText: '€4.99 por 3 meses',
		disclaimerText:
			'*€4.99/mes durante los primeros 3 meses, luego €9.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	CH: {
		offerText: '€4.99 für 3 Monate',
		disclaimerText:
			'*€4.99/Monat für die ersten 3 Monate, dann €9.99/Monat nur für berechtigte Abonnenten auf berechtigten Geräten. Es gelten die Bedingungen.',
	},
	IT: {
		offerText: '€4.99 per 3 mesi',
		disclaimerText:
			'*€4.99/mese per i primi 3 mesi, poi €9.99/mese solo per gli abbonati idonei su dispositivi idonei. Si applicano i termini.',
	},
	MX: {
		offerText: 'MXN69.00 por 3 meses',
		disclaimerText:
			'*MXN69.00/mes durante los primeros 3 meses, luego MXN129.00/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	FI: {
		offerText: '€4.99 3 kuukaudeksi',
		disclaimerText:
			'*€4.99/kk ensimmäiset 3 kuukautta, sitten €9.99/kk vain kelvollisille tilaajille kelvollisilla laitteilla. Ehdot pätevät.',
	},
	BE: {
		offerText: '€4.99 pour 3 mois',
		disclaimerText:
			'*€4.99/mois pendant les 3 premiers mois, puis €9.99/mois pour les abonnés éligibles sur les appareils éligibles uniquement. Conditions applicables.',
	},
	PH: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	NL: {
		offerText: '€4.99 voor 3 maanden',
		disclaimerText:
			'*€4.99/month for first 3 months, then €9.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	BR: {
		offerText: 'R$9.90 por 3 meses',
		disclaimerText:
			'*R$9.90/mês durante os primeiros 3 meses, depois R$21.90/mês apenas para assinantes elegíveis em dispositivos elegíveis. Aplicam-se termos.',
	},
	PT: {
		offerText: '€4.99 por 3 meses',
		disclaimerText:
			'*€4.99/mês durante os primeiros 3 meses, depois €9.99/mês apenas para assinantes elegíveis em dispositivos elegíveis. Aplicam-se termos.',
	},
	IE: {
		offerText: '€4.99 for 3 months',
		disclaimerText:
			'*€4.99/month for first 3 months, then €9.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	DK: {
		offerText: '€4.99 for 3 måneder',
		disclaimerText:
			'*€4.99/md. i de første 3 måneder, derefter €9.99/md. kun for kvalificerede abonnenter på kvalificerede enheder. Betingelser gælder.',
	},
	NO: {
		offerText: '€4.99 for 3 måneder',
		disclaimerText:
			'*€4.99/mnd for de første 3 månedene, deretter €9.99/mnd kun for kvalifiserte abonnenter på kvalifiserte enheter. Vilkår gjelder.',
	},
	ID: {
		offerText: '$1.99 selama 3 bulan',
		disclaimerText:
			'*$1.99/bulan selama 3 bulan pertama, kemudian $6.99/bulan hanya untuk pelanggan yang memenuhi syarat di perangkat yang memenuhi syarat. Syarat berlaku.',
	},
	CZ: {
		offerText: '€4.99 na 3 měsíce',
		disclaimerText:
			'*€4.99/měsíc po dobu prvních 3 měsíců, poté €9.99/měsíc pouze pro oprávněné předplatitele na oprávněných zařízeních. Platí podmínky.',
	},
	SE: {
		offerText: '€4.99 för 3 månader',
		disclaimerText:
			'*€4.99/månad i 3 månader, sedan €9.99/månad endast för berättigade prenumeranter på berättigade enheter. Villkor gäller.',
	},
	AE: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم $6.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	CO: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	GR: {
		offerText: '€4.99 για 3 μήνες',
		disclaimerText:
			'*€4.99/μήνα για τους πρώτους 3 μήνες, μετά €9.99/μήνα μόνο για επιλέξιμους συνδρομητές σε επιλέξιμες συσκευές. Ισχύουν όροι.',
	},
	IL: {
		offerText: '$1.99 למשך 3 חודשים',
		disclaimerText:
			'*$1.99/לחודש למשך 3 חודשים, לאחר מכן $6.99/לחודש למנויים זכאים בלבד ובמכשירים תואמים. בכפוף לתנאים.',
	},
	SA: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم $6.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	PL: {
		offerText: '€4.99 za 3 miesiące',
		disclaimerText:
			'*€4.99/miesiąc przez pierwsze 3 miesiące, następnie €9.99/miesiąc tylko dla uprawnionych subskrybentów na uprawnionych urządzeniach. Obowiązują warunki.',
	},
	CL: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	AR: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	HU: {
		offerText: '€4.99 3 hónapra',
		disclaimerText:
			'*€4.99/hónap az első 3 hónapban, majd €6.99/hónap csak jogosult előfizetőknek jogosult eszközökön. Feltételek érvényesek.',
	},
	SG: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $9.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	ZA: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	IN: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	AT: {
		offerText: '€4.99 für 3 Monate',
		disclaimerText:
			'*€4.99/Monat für die ersten 3 Monate, dann €9.99/Monat nur für berechtigte Abonnenten auf berechtigten Geräten. Es gelten die Bedingungen.',
	},
	TH: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	NZ: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $7.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	MY: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	PE: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $9.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	LT: {
		offerText: '€4.99 už 3 mėnesius',
		disclaimerText:
			'*€4.99/mėn. pirmus 3 mėnesius, vėliau €9.99/mėn. tik tinkamiems prenumeratoriams tinkamuose įrenginiuose. Taikomos sąlygos.',
	},
	UA: {
		offerText: '$1.99 за 3 місяці',
		disclaimerText:
			'*$1.99/місяць протягом перших 3 місяців, потім $6.99/місяць лише для відповідних абонентів на відповідних пристроях. Застосовуються умови.',
	},
	DO: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	BG: {
		offerText: '€4.99 за 3 месеца',
		disclaimerText:
			'*€4.99/месец за първите 3 месеца, след това €9.99/месец само за допустими абонати на допустими устройства. Приложими условия.',
	},
	JP: {
		offerText: '3か月で¥200.00',
		disclaimerText:
			'*最初の3か月間は¥200.00/月、その後は¥900.00/月（対象デバイスの対象ユーザーのみ）。利用規約が適用されます。',
	},
	SK: {
		offerText: '€4.99 na 3 mesiace',
		disclaimerText:
			'*€4.99/mesiac počas prvých 3 mesiacov, potom €9.99/mesiac iba pre oprávnených predplatiteľov na oprávnených zariadeniach. Platí podmienky.',
	},
	CR: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	EC: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $9.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	EG: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم$6.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	LV: {
		offerText: '€4.99 uz 3 mēnešiem',
		disclaimerText:
			'*€4.99/mēnesī pirmajos 3 mēnešos, pēc tam €9.99/mēnesī tikai piemērotajiem abonentiem piemērotās ierīcēs. Piemēro noteikumus.',
	},
	VN: {
		offerText: '$1.99 trong 3 tháng',
		disclaimerText:
			'*$1.99/tháng trong 3 tháng đầu, sau đó $6.99/tháng chỉ dành cho người đăng ký đủ điều kiện trên thiết bị đủ điều kiện. Áp dụng điều khoản.',
	},
	LU: {
		offerText: '€4.99 pour 3 mois',
		disclaimerText:
			'*€4.99/mois pendant les 3 premiers mois, puis €9.99/mois pour les abonnés éligibles sur les appareils éligibles uniquement. Conditions applicables.',
	},
	GT: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	HK: {
		offerText: '3个月 $1.99',
		disclaimerText: '*前3个月每月$1.99，之后每月$6.99，仅适用于符合条件的设备和用户。适用条款。',
	},
	SI: {
		offerText: '€4.99 za 3 mesece',
		disclaimerText:
			'*€4.99/mesec za prve 3 mesece, nato €9.99/mesec samo za upravičene naročnike na upravičenih napravah. Veljajo pogoji.',
	},
	EE: {
		offerText: '€4.99 3 kuu eest',
		disclaimerText:
			'*€4.99/kuu esimese 3 kuu jooksul, seejärel €9.99/kuu ainult sobilikele tellijatele sobilikes seadmetes. Kehtivad tingimused.',
	},
	QA: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم $6.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	LK: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	PA: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	PY: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	CY: {
		offerText: '€4.99 για 3 μήνες',
		disclaimerText:
			'*€4.99/μήνα για τους πρώτους 3 μήνες, μετά €9.99/μήνα μόνο για επιλέξιμους συνδρομητές σε επιλέξιμες συσκευές. Ισχύουν όροι.',
	},
	TW: {
		offerText: '3个月 $1.99',
		disclaimerText: '*前3个月每月$1.99，之后每月$6.99，仅适用于符合条件的设备和用户。适用条款。',
	},
	MN: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	HN: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	MT: {
		offerText: '€4.99 for 3 months',
		disclaimerText:
			'*€4.99/month for first 3 months, then €9.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	BO: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	MU: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	JO: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم $9.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	GH: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	KH: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	MD: {
		offerText: '$1.99 pentru 3 luni',
		disclaimerText:
			'*$1.99/lună pentru primele 3 luni, apoi $6.99/lună doar pentru abonații eligibili pe dispozitive eligibile. Se aplică termeni.',
	},
	AZ: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	TT: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	BH: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم $6.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	BS: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	AM: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	SV: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	NI: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	OM: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم $6.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	NA: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	UG: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	FJ: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	LB: {
		offerText: '$1.99 لمدة 3 أشهر',
		disclaimerText:
			'*$1.99/شهريًا لأول 3 أشهر، ثم $6.99/شهريًا للمشتركين المؤهلين فقط وعلى الأجهزة المؤهلة. تسري الشروط.',
	},
	BY: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	ZW: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	BW: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	MZ: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mês durante os primeiros 3 meses, depois $6.99/mês apenas para assinantes elegíveis em dispositivos elegíveis. Aplicam-se termos.',
	},
	KY: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	VE: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
	BM: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	GD: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	GW: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mês durante os primeiros 3 meses, depois $6.99/mês apenas para assinantes elegíveis em dispositivos elegíveis. Aplicam-se termos.',
	},
	AG: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	KN: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	SZ: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	AI: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	TJ: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	BZ: {
		offerText: '$1.99 for 3 months',
		disclaimerText:
			'*$1.99/month for first 3 months, then $6.99/month for eligible subscribers on eligible devices only. Terms apply.',
	},
	HR: {
		offerText: '$1.99 por 3 meses',
		disclaimerText:
			'*$1.99/mes durante los primeros 3 meses, luego $6.99/mes solo para suscriptores elegibles en dispositivos elegibles. Se aplican términos.',
	},
};
