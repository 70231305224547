import { inject } from 'vue';

import type {
	VisibilityChangedPayload,
	BatchImpressionTrackingOptions,
} from '@/helpers/composables/useBatchImpressionTracking';
import { useBatchImpressionTracking } from '@/helpers/composables/useBatchImpressionTracking';

import { SraEventAction } from '@/@types/graphql-types';

import { SnowplowTitleContextGraphql } from '@/helpers/tracking/providers/snowplow-contexts';
import { getSrPlacement, SR_PROVIDER_KEY, SRCatalogueKey } from '@/components/sponsored-recommendations/useSendBidId';
import { useBackendTracking } from './useBackendTracking';
import { PosterTitle } from '@/interfaces/components/poster/PosterGraphql';
import { isTitleSeason } from '@/helpers/title-helper';

export type PosterVisibilityChangedPayload = VisibilityChangedPayload<any>;
export interface ImpressionTrackingPosterDetails {
	title: PosterTitle;
	onVisibilityCallback?: (title: PosterTitle) => void;
}

/**
 * Impression Tracking handles
 * - Sponsored Recommendation's holdout
 * - Fires when the poster is visible in the user's viewport
 * - Snowplow Title Context Graphql
 *
 * @param option: BatchImpressionTrackingOptions
 * @returns ImpressionTrackingPosterDetails
 */
export function usePosterImpressionTracking(options: BatchImpressionTrackingOptions) {
	const { trackSraEvent } = useBackendTracking();
	const srCategoryKey = inject<SRCatalogueKey | undefined>(SR_PROVIDER_KEY, undefined);

	// Called when component is visible on the screen
	function onVisibilityCallback(options: ImpressionTrackingPosterDetails) {
		options.onVisibilityCallback?.(options.title);

		if (srCategoryKey == null) return;
		const sponsoredAd = getSrPlacement(srCategoryKey);

		if (sponsoredAd?.campaign == null || sponsoredAd?.bidId == null) return;

		// Check to see if the holdout campaign matches the poster's entityId
		if (sponsoredAd?.holdoutGroup && sponsoredAd?.campaign?.node?.nodeId === options.title.id) {
			trackSraEvent({
				action: SraEventAction.Impression,
				sponsoredAd: sponsoredAd,
			});
		}
	}

	return useBatchImpressionTracking<ImpressionTrackingPosterDetails>({
		category: 'title',
		toContext: getPosterImpressionContext,
		observableOptions: options.observableOptions,
		groupId: options.groupId,
		additionalContexts: options.additionalContexts,
		onVisibilityCallback,
	});
}

function getPosterImpressionContext({ title }: ImpressionTrackingPosterDetails): SnowplowTitleContextGraphql {
	const _title = isTitleSeason(title) ? title.show : title;

	return new SnowplowTitleContextGraphql(
		_title.objectId,
		_title.objectType,
		isTitleSeason(title) ? title?.content?.seasonNumber : undefined
	);
}
